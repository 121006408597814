<template lang="">
  <div class="mt-3 md:mt-0">
    <t-dropdown variant="smActions">
      <template
        #trigger="{
          mousedownHandler,
          focusHandler,
          blurHandler,
          keydownHandler, 
        }"
      >
        <button
          class="text-oDark focus:outline-none"
          aria-label="Task Status Menu"
          aria-haspopup="true"
          @mousedown="mousedownHandler"
          @focus="focusHandler"
          @blur="blurHandler"
          @keydown="keydownHandler"
        >
          <i class="text-sm text-gray-600 fas fa-ellipsis-v" />
        </button>
      </template>

      <template #default="{ hide }">
        <div
          @click="hide()"
          class="py-1 mt-px bg-white rounded-md shadow-md z-200"
        >
          <t-dropdown-item
            @click="executeAction('notifyRiders')"
            v-if="data.status !== 'Expired'"
          >
            {{ $t('components.subscriptionManagement.action.notifyRider') }}
          </t-dropdown-item>
          <t-dropdown-item @click="executeAction('delete')" variant="danger">
            {{ $t('components.subscriptionManagement.action.delete') }}
          </t-dropdown-item>
        </div>
      </template>
    </t-dropdown>
    <NotifyModal
      :id="`notify-${data.id}`"
      :key="`notify-${data.id}`"
      :isLoading="isLoading"
      :title="$t('components.subscriptionManagement.modals.notifyModal.title')"
      :subtitle="
        $t('components.subscriptionManagement.modals.notifyModal.subTitle')
      "
      :cancelButtonText="
        $t('components.subscriptionManagement.modals.notifyModal.cancel')
      "
      :cancelButtonVariant="`secondary`"
      :notify-button-text="
        $t('components.subscriptionManagement.modals.notifyModal.notify')
      "
      :notify-button-variant="`green`"
      @setQueryString="(query) => (queryString = query)"
      @notify="actionNotify"
      @cancel="executeAction('hideNotifyModal')"
    />
  </div>
</template>
<script>
import { useEndpoints } from '@/composables'
import { PassConfig } from '@/config/PassConfig'
import { convertToTitleCase } from '@/components/picker/date-range/utils/CollectionUtils'
export default {
  name: 'TaskMoreActionsDropdown',
  components: {
    TDropdownItem: () => import('@/components/dropdown/TDropdownItem'),
    NotifyModal: () => import('@/components/modals/NotifyModal'),
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
    queryString: null,
  }),
  methods: {
    async actionNotify() {
      this.isLoading = true
      const url =
        useEndpoints.pass.notifyRiders(this.data.id) + this.queryString

      await this.$http
        .post(url)
        .then((res) => {
          console.log('notifySuccess--', { res })
          this.$modal.hide(`notify-${this.data.id}`)
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Successful',
              text: res.data.message,
            },
            3000
          )
        })
        .catch((err) => {
          console.log('notifyErr--', { err })
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: `Error Occurred [${err.response.status}]`,
              text: err.response.data.message,
            },
            3000
          )
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async executeAction(type) {
      if (this.$acl.canNotUpdate('pass')) {
        this.$notify(
          this.$acl.notAbleToToast({
            action: 'notify',
            permission: 'pass',
          })
        )
        return
      }
      if (type === 'notifyRiders') {
        if (['Inactive'].includes(this.data.status)) {
          this.$notify(
            {
              group: 'generic',
              type: 'default',
              title: 'Inactive Pass!',
              text:
                "You can't notify riders for Inactive Pass. Please activate the Pass first.",
            },
            5000
          )
          return
        }

        this.$modal.show(`notify-${this.data.id}`)
      }
      if (type === 'hideNotifyModal') {
        this.$modal.hide(`notify-${this.data.id}`)
      }
      if (type === 'delete') {
        const endpoint = PassConfig.api.delete(this.data.id)
        this.isLoading = true
        await this.$http
          .delete(endpoint)
          .then((res) => {
            console.log('delete', res.data)

            this.$notify({
              group: 'bottomRight',
              type: `success`,
              title: `Success`,
              text: `Subscription has been deleted successfully`,
            })
            this.$emit('refresh')
          })
          .catch((error) => {
            const e = error.response
            console.error('v-catch', e)

            this.$notify(
              {
                group: 'bottomLeft',
                type: 'error',
                title: e.data.code
                  ? `${convertToTitleCase(e.data.code)}`
                  : `Error Occured [Code: ${e.status}]`,
                text: e.data.message ?? 'The action could not be executed',
              },
              5000
            )
          })
          .finally(() => {
            this.isLoading = false
          })
        return
      }
    },
  },
}
</script>
<style lang=""></style>
