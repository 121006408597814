var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-3 md:mt-0"},[_c('t-dropdown',{attrs:{"variant":"smActions"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
      var mousedownHandler = ref.mousedownHandler;
      var focusHandler = ref.focusHandler;
      var blurHandler = ref.blurHandler;
      var keydownHandler = ref.keydownHandler;
return [_c('button',{staticClass:"text-oDark focus:outline-none",attrs:{"aria-label":"Task Status Menu","aria-haspopup":"true"},on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[_c('i',{staticClass:"text-sm text-gray-600 fas fa-ellipsis-v"})])]}},{key:"default",fn:function(ref){
      var hide = ref.hide;
return [_c('div',{staticClass:"py-1 mt-px bg-white rounded-md shadow-md z-200",on:{"click":function($event){return hide()}}},[(_vm.data.status !== 'Expired')?_c('t-dropdown-item',{on:{"click":function($event){return _vm.executeAction('notifyRiders')}}},[_vm._v(" "+_vm._s(_vm.$t('components.subscriptionManagement.action.notifyRider'))+" ")]):_vm._e(),_c('t-dropdown-item',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.executeAction('delete')}}},[_vm._v(" "+_vm._s(_vm.$t('components.subscriptionManagement.action.delete'))+" ")])],1)]}}])}),_c('NotifyModal',{key:("notify-" + (_vm.data.id)),attrs:{"id":("notify-" + (_vm.data.id)),"isLoading":_vm.isLoading,"title":_vm.$t('components.subscriptionManagement.modals.notifyModal.title'),"subtitle":_vm.$t('components.subscriptionManagement.modals.notifyModal.subTitle'),"cancelButtonText":_vm.$t('components.subscriptionManagement.modals.notifyModal.cancel'),"cancelButtonVariant":"secondary","notify-button-text":_vm.$t('components.subscriptionManagement.modals.notifyModal.notify'),"notify-button-variant":"green"},on:{"setQueryString":function (query) { return (_vm.queryString = query); },"notify":_vm.actionNotify,"cancel":function($event){return _vm.executeAction('hideNotifyModal')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }