var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('slide-over-right',{attrs:{"id":_vm.sorId,"editType":"pass"},on:{"showModal":_vm.showModal}},[_c('loading',{attrs:{"active":_vm.isLoading},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('div',{staticClass:"flex flex-wrap px-6 pt-12 w-full"},[_c('div',{staticClass:"items-center w-1/2 panel-title header-title"},[_vm._v(" "+_vm._s(_vm.titleText)+" ")])]),_c('div',{staticClass:"px-6 mt-2"},[_c('pass-card',{attrs:{"minuteAmountOfPass":_vm.form.free_riding_time_minutes,"currencySymbol":_vm.currencySymbol,"passPrice":_vm.form.price,"days":_vm.form.period,"passCategory":_vm.form.category,"isUnlockDiscountApplied":!!_vm.show_unlock_charge,"unlockDiscount":_vm.form.unlock_charge,"isReservationDiscountApplied":!!_vm.show_reservation_charge,"reservationDiscount":_vm.form.reservation_charge,"isMinuteChargeDiscountApplied":!!_vm.show_per_minute_charge,"perMinuteChargeDiscount":_vm.form.per_minute_charge,"isFreeMinutesPerRideApplied":!!_vm.show_free_minutes_each_ride,"freeMinutesPerRide":_vm.form.free_minutes_each_ride,"passStartDate":_vm.getPassStartDate,"passExpiredDate":_vm.getPassExpiredDate}}),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"sm:pb-24 2xl:px-6 2xl:pb-3 sm:px-1",attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.confirmSubmit)}}},[_c('div',{staticClass:"flex items-center pt-8"},[_c('div',{staticClass:"w-1/2"},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
                  'components.subscriptionManagement.addEdit.steps.subscription.title.subscriptionValueType'
                ),"label":_vm.$t(
                  'components.subscriptionManagement.addEdit.steps.subscription.title.subscriptionValueType'
                ),"placeholder":_vm.$t(
                  'components.subscriptionManagement.addEdit.steps.subscription.placeHolder.subscriptionValueType'
                ),"infoDescription":_vm.$t(
                  'components.subscriptionManagement.addEdit.steps.subscription.infoDescription.subscriptionValueType'
                ),"options":_vm.passCategoryChoices,"hide-search-box":"","rules":"required"},model:{value:(_vm.form.category),callback:function ($$v) {_vm.$set(_vm.form, "category", $$v)},expression:"form.category"}})],1),_c('div',{staticClass:"pl-2 w-1/2"},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
                  'components.subscriptionManagement.addEdit.steps.subscription.title.subscriptionType'
                ),"label":_vm.$t(
                  'components.subscriptionManagement.addEdit.steps.subscription.title.subscriptionType'
                ),"placeholder":_vm.$t(
                  'components.subscriptionManagement.addEdit.steps.subscription.placeHolder.subscriptionType'
                ),"options":_vm.passTypes,"hide-search-box":"","rules":"required"},model:{value:(_vm.form.period),callback:function ($$v) {_vm.$set(_vm.form, "period", $$v)},expression:"form.period"}})],1)]),_c('div',[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
                'components.subscriptionManagement.addEdit.steps.subscription.title.assignedFleet'
              ),"label":_vm.$t(
                'components.subscriptionManagement.addEdit.steps.subscription.title.assignedFleet'
              ),"placeholder":_vm.$t(
                'components.subscriptionManagement.addEdit.steps.subscription.placeHolder.assignedFleet'
              ),"options":_vm.getFleetOptions,"hide-search-box":"","rules":"required"},model:{value:(_vm.form.assigned_fleet),callback:function ($$v) {_vm.$set(_vm.form, "assigned_fleet", $$v)},expression:"form.assigned_fleet"}})],1),_c('div',[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
                'components.subscriptionManagement.addEdit.steps.subscription.title.vehicleType'
              ),"label":_vm.$t(
                'components.subscriptionManagement.addEdit.steps.subscription.title.vehicleType'
              ),"placeholder":_vm.$t(
                'components.subscriptionManagement.addEdit.steps.subscription.placeHolder.vehicleType'
              ),"options":_vm.vehicleTypes,"hide-search-box":"","rules":"required"},model:{value:(_vm.form.vehicle_type),callback:function ($$v) {_vm.$set(_vm.form, "vehicle_type", $$v)},expression:"form.vehicle_type"}})],1),(_vm.form.category === 1)?_c('div',[_c('AppInput',{attrs:{"type":"number","rules":"required","label":_vm.$t(
                'components.subscriptionManagement.addEdit.steps.subscription.title.ridingTime'
              ),"infoDescription":_vm.$t(
                'components.subscriptionManagement.addEdit.steps.subscription.infoDescription.ridingTime'
              ),"placeholder":"e.g. 60","haveUnitText":true,"unitText":"Min"},model:{value:(_vm.form.free_riding_time_minutes),callback:function ($$v) {_vm.$set(_vm.form, "free_riding_time_minutes", $$v)},expression:"form.free_riding_time_minutes"}})],1):_vm._e(),_c('div',[_c('AppInput',{attrs:{"type":"number","step":"0.01","rules":"required","label":_vm.$t(
                'components.subscriptionManagement.addEdit.steps.subscription.title.subscriptionPrice'
              ),"placeholder":"e.g. 49.99","haveUnitText":true,"unitText":_vm.currencySymbol},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}})],1),_c('div',{staticClass:"mt-2"},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
                'components.subscriptionManagement.addEdit.steps.subscription.title.status'
              ),"label":_vm.$t(
                'components.subscriptionManagement.addEdit.steps.subscription.title.status'
              ),"placeholder":_vm.$t(
                'components.subscriptionManagement.addEdit.steps.subscription.placeHolder.status'
              ),"options":_vm.statusOptions,"hide-search-box":"","rules":"required"},model:{value:(_vm.is_active),callback:function ($$v) {_vm.is_active=$$v},expression:"is_active"}})],1),(_vm.form.category === 0)?_c('div',{staticClass:"mt-2"},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
                'components.subscriptionManagement.addEdit.steps.subscription.title.discountUnlockChargeAllowed'
              ),"label":_vm.$t(
                'components.subscriptionManagement.addEdit.steps.subscription.title.discountUnlockChargeAllowed'
              ),"placeholder":_vm.$t(
                'components.subscriptionManagement.addEdit.steps.subscription.placeHolder.discountUnlockChargeAllowed'
              ),"options":_vm.unLockCharge,"hide-search-box":""},model:{value:(_vm.show_unlock_charge),callback:function ($$v) {_vm.show_unlock_charge=$$v},expression:"show_unlock_charge"}})],1):_vm._e(),(_vm.form.category === 0 && _vm.show_unlock_charge == 1)?_c('div',{staticClass:"flex items-center pt-4"},[_c('AppInput',{attrs:{"type":"number","step":"0.01","rules":"required|between:0,100","label":_vm.$t(
                'components.subscriptionManagement.addEdit.steps.subscription.title.discountUnlockCharge'
              ),"placeholder":"e.g. 3","haveUnitText":true,"unitText":"%"},model:{value:(_vm.form.unlock_charge),callback:function ($$v) {_vm.$set(_vm.form, "unlock_charge", $$v)},expression:"form.unlock_charge"}})],1):_vm._e(),(_vm.form.category === 0)?_c('div',{staticClass:"mt-2"},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
                'components.subscriptionManagement.addEdit.steps.subscription.title.discountReservationChargeAllowed'
              ),"label":_vm.$t(
                'components.subscriptionManagement.addEdit.steps.subscription.title.discountReservationChargeAllowed'
              ),"placeholder":_vm.$t(
                'components.subscriptionManagement.addEdit.steps.subscription.placeHolder.discountReservationChargeAllowed'
              ),"options":_vm.reservationCharge,"hide-search-box":""},model:{value:(_vm.show_reservation_charge),callback:function ($$v) {_vm.show_reservation_charge=$$v},expression:"show_reservation_charge"}})],1):_vm._e(),(_vm.form.category === 0 && _vm.show_reservation_charge == 1)?_c('div',{staticClass:"flex items-center pt-4"},[_c('AppInput',{attrs:{"type":"number","step":"0.01","rules":"required|between:0,100","label":_vm.$t(
                'components.subscriptionManagement.addEdit.steps.subscription.title.discountReservationCharge'
              ),"placeholder":"e.g. 3","haveUnitText":true,"unitText":"%"},model:{value:(_vm.form.reservation_charge),callback:function ($$v) {_vm.$set(_vm.form, "reservation_charge", $$v)},expression:"form.reservation_charge"}})],1):_vm._e(),(_vm.form.category === 0)?_c('div',{staticClass:"mt-2"},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
                'components.subscriptionManagement.addEdit.steps.subscription.title.discountPerMinutesChargeAllowed'
              ),"label":_vm.$t(
                'components.subscriptionManagement.addEdit.steps.subscription.title.discountPerMinutesChargeAllowed'
              ),"placeholder":_vm.$t(
                'components.subscriptionManagement.addEdit.steps.subscription.placeHolder.discountPerMinutesChargeAllowed'
              ),"options":_vm.perCharges,"hide-search-box":""},model:{value:(_vm.show_per_minute_charge),callback:function ($$v) {_vm.show_per_minute_charge=$$v},expression:"show_per_minute_charge"}})],1):_vm._e(),(_vm.form.category === 0 && _vm.show_per_minute_charge == 1)?_c('div',{staticClass:"flex items-center pt-4"},[_c('AppInput',{attrs:{"type":"number","step":"0.01","rules":"required|between:0,100","label":_vm.$t(
                'components.subscriptionManagement.addEdit.steps.subscription.title.discountPerMinuteCharge'
              ),"placeholder":"e.g. 15","haveUnitText":true,"unitText":"%"},model:{value:(_vm.form.per_minute_charge),callback:function ($$v) {_vm.$set(_vm.form, "per_minute_charge", $$v)},expression:"form.per_minute_charge"}})],1):_vm._e(),(_vm.form.category === 0)?_c('div',{staticClass:"mt-2"},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
                'components.subscriptionManagement.addEdit.steps.subscription.title.freeTimeAllowed'
              ),"label":_vm.$t(
                'components.subscriptionManagement.addEdit.steps.subscription.title.freeTimeAllowed'
              ),"placeholder":_vm.$t(
                'components.subscriptionManagement.addEdit.steps.subscription.placeHolder.freeTimeAllowed'
              ),"options":_vm.perCharges,"hide-search-box":""},model:{value:(_vm.show_free_minutes_each_ride),callback:function ($$v) {_vm.show_free_minutes_each_ride=$$v},expression:"show_free_minutes_each_ride"}})],1):_vm._e(),(_vm.form.category === 0 && _vm.show_free_minutes_each_ride == 1)?_c('div',{staticClass:"flex items-center pt-4"},[_c('AppInput',{attrs:{"type":"number","rules":"required|between:0,100000","label":_vm.$t(
                'components.subscriptionManagement.addEdit.steps.subscription.title.freeTimeEachRide'
              ),"placeholder":"e.g. 30","haveUnitText":true,"unitText":"Min"},model:{value:(_vm.form.free_minutes_each_ride),callback:function ($$v) {_vm.$set(_vm.form, "free_minutes_each_ride", $$v)},expression:"form.free_minutes_each_ride"}})],1):_vm._e(),_c('div',{staticClass:"flex items-center pt-6"},[_c('div',{staticClass:"pr-2 w-1/2"},[_c('AppInput',{attrs:{"type":"date","rules":"required","label":_vm.$t(
                  'components.subscriptionManagement.addEdit.steps.subscription.title.subscriptionStartDate'
                )},on:{"input":_vm.onChangeStartDate},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}})],1),_c('div',{staticClass:"pl-2 w-1/2"},[_c('AppInput',{attrs:{"type":"date","rules":"required","label":_vm.$t(
                  'components.subscriptionManagement.addEdit.steps.subscription.title.subscriptionExpireDate'
                )},on:{"input":_vm.onChangeExpireDate},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}})],1)]),_c('button',{ref:"submitButton",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v("Save")])])]}}])}),_c('div',{staticClass:"flex justify-end items-center pr-2 mt-6"},[_c('slide-over-right-trigger',{attrs:{"id":_vm.sorId}},[_c('anchor-button',{attrs:{"variant":"secondary"}},[_vm._v(_vm._s(_vm.$t('components.stepNavigation.cancel')))])],1),_c('t-button',{staticClass:"ml-3",attrs:{"type":"submit"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(_vm._s(_vm.actionButtonText))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }